import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PosterTitle } from "../commons/PosterContainer.style"
import {
  BlogContent,
  BlogTitle,
  Container,
  ImgContainer,
  MainContainer,
} from "./styled"

const Reflections = ({ blogData }) => (
  <MainContainer>
    <PosterTitle color="#00000">REFLECTIONS</PosterTitle>
    {blogData.allMarkdownRemark.nodes.map(({ frontmatter }, index) => (
      <Container key={index}>
        <ImgContainer>
          <Link to={`/reflection/blog/${frontmatter.slug}`}>
            <GatsbyImage
              image={getImage(frontmatter.img.childImageSharp)}
              alt={frontmatter.title}
            />
          </Link>
        </ImgContainer>
        <BlogContent>
          <Link to={`/reflection/blog/${frontmatter.slug}`}>
            <BlogTitle>{frontmatter.title}</BlogTitle>
            <p>
              {frontmatter.content}
              <span style={{ color: "#959595" }}>read more</span>
            </p>
          </Link>
        </BlogContent>
      </Container>
    ))}
  </MainContainer>
)

export default Reflections
