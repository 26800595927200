import styled from "@emotion/styled"

export const MainContainer = styled.div`
  margin: 10px 0;
  width: 84%;
  margin: auto;

  @media (max-width: 1024px) {
    width: 95%;
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 70%;
  margin: 50px;

  a {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const ImgContainer = styled.div``

export const BlogTitle = styled.div`
  font-family: "Marcellus";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 35px;
  text-transform: capitalize;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 34px;
    margin: 10px 0;
  }
`

export const BlogContent = styled.div`
  margin-left: 10%;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    font-family: "Marcellus";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-decoration: none;
    color: black;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    text-align: center;

    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
`

export const Box = styled.div`
  width: 352px;
  height: 352px;
  background: #c4c4c4;
`
