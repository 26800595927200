import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Reflections from "../components/reflections"
import Seo from "../components/seo"
const logo = require("../images/logo.svg").default

const ResearcherPage = ({ data }) => (
  <Layout
    backgroundColor="white"
    logo={logo}
    menuBGColor="black"
    hamburgerColor="black"
    footerColor="#171b32"
  >
    <Seo title="Rajyashree Ramesh" />
    <Reflections blogData={data} />
  </Layout>
)

export default ResearcherPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      nodes {
        html
        frontmatter {
          content
          slug
          title
          img {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
              id
            }
          }
        }
      }
    }
  }
`
